* {
  margin: 0;
  padding: 0;
  box-sizing: 0;
}

body {
  font-family: "Poppins", sans-serif;
  background: linear-gradient(45deg, #1a2980, #26d0ce);
  height: 100vh;
}

.hero {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.hero-title {
  font-size: 4rem;
  font-weight: bold;
  animation: fadeIn 1.5s ease-in-out;
  letter-spacing: 2px;
}

.hero-subtitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  animation: fadeIn 2s ease-in-out;
}

.loader {
  position: relative;
  width: 150px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  margin: 20px auto;
}

.loader-bar {
  position: absolute;
  height: 100%;
  width: 0;
  background-color: #ffffff;
  animation: loading 2s infinite;
  border-radius: 2px;
}

@keyframes loading {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Footer */
.footer {
  position: fixed;
  bottom: 10px;
  width: 100%;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 1px;
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px 0;
}
